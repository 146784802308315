<template>       
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <!-- <el-form :inline='true'> -->
                    <!-- <el-form-item label='省:'>
                        <el-select 
                        v-model.number="filters.provinceID" 
                        placeholder="请选择" 
                        @change='provinceChange' 
                        v-loading='provinceLoading'
                        :clearable="userInfoByToken.ProvinceID == null ? true : false ">
                            <el-option
                            v-for="item in provinceOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='provinceIsDisabled'>
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label='市:'>
                        <el-select 
                        v-model.number="filters.cityID" 
                        placeholder="请选择" 
                        @change='cityChange' 
                        v-loading='cityLoading'
                        :clearable="userInfoByToken.CityID == null ? true : false">
                            <el-option
                            v-for="item in cityOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='cityIsDisabled'>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='区/县:'>
                        <el-select 
                        v-model.number="filters.regionID" 
                        placeholder="请选择" 
                        v-loading='areaLoading'
                        :clearable="userInfoByToken.RegionID == null ? true : false"
                        >
                            <el-option
                            v-for="item in areaOptions"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                            :disabled='areaIsDisabled'>
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="地区:">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label='证书数量:'>
                        <el-select v-model="filters.certificateRegisterCount" placeholder="请选择证书数量">
                            <el-option
                            v-for="item in certificatOptions"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div style='display:flex'>
                            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                            <el-button @click.native='getSum' type='primary'>汇总总计</el-button>
                        </div>                     
                    </el-form-item>
                </el-form> -->
                <div class="areaBox">
                    <el-menu :default-active="proActive" @select="proSelect" class="el-menu-demo" mode="horizontal">
                        <el-menu-item index="1919">广东省</el-menu-item>
                    </el-menu>
                </div>
                <div class="areaBox" v-if="cityArr.length > 0">
                    <el-menu :default-active="cityActive" @select="citySelect" class="el-menu-demo" mode="horizontal">
                        <el-menu-item v-for="(item,index) in cityArr" :index="String(item.ID)" :key="index">{{item.Name}}</el-menu-item>
                    </el-menu>
                </div>
                <div class="areaBox" v-if="RegionArr.length > 0">
                    <el-menu :default-active="regionActive" @select="regionSelect" class="el-menu-demo" mode="horizontal">
                        <el-menu-item v-for="(item,index) in RegionArr" :index="String(item.ID)" :key="index">{{item.Name}}</el-menu-item>
                    </el-menu>
                </div>
                <div class="areaBox" v-if="TownArr.length > 0">
                    <el-menu :default-active="townActive" @select="townSelect" class="el-menu-demo" mode="horizontal">
                        <el-menu-item v-for="(item,index) in TownArr" :index="String(item.ID)" :key="index">{{item.Name}}</el-menu-item>
                    </el-menu>
                </div>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            style="width: 100%"
            border
            highlight-current-row
            :cell-style='rowClass'
            v-loading='listLoading'
            :header-cell-style='headerClass'
            @current-change='selCurrentRow'
            v-if="!TownID"
        >
            <el-table-column type='index' label="序号" width="60"></el-table-column>
            <!-- <el-table-column prop='Province' label='省'></el-table-column> -->
            <el-table-column prop='City' :label='TownID ? "所属镇" : "地区"'>
                <template scope="scope">
                    {{TownID ? scope.row.Town : scope.row.City}}
                </template>
            </el-table-column>
            <!-- <el-table-column prop='Region' label='区/县'></el-table-column> -->
            <el-table-column prop='SocialUnitCount' label='社会单位数量' sortable min-width="140">
                <template scope="scope">
                    {{scope.row.SocialUnitCount ? scope.row.SocialUnitCount : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerCount2' label='2本及以上证书单位数' sortable min-width="190">
                <template scope="scope">
                    {{scope.row.CerCount2 ? scope.row.CerCount2 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerCount1' label='1本证书单位数' sortable min-width="150">
                <template scope="scope">
                    {{scope.row.CerCount1 ? scope.row.CerCount1 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerCount0' label='无证书单位数' sortable min-width="150">
                <template scope="scope">
                    {{scope.row.CerCount0 ? scope.row.CerCount0 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerProportion' label='持证率' sortable min-width="110">
                <template scope="scope">
                    {{(scope.row.CerProportion == '0' || scope.row.CerProportion == 'null' || scope.row.CerProportion == null) ? 0 : scope.row.CerProportion == "-" ? "-" : scope.row.CerProportion + '‱'}}
                </template>
            </el-table-column>
        </el-table>
        <el-table
            :data="tableData"
            style="width: 100%"
            border
            highlight-current-row
            :cell-style='rowClass'
            v-loading='listLoading'
            :header-cell-style='headerClass'
            @current-change='selCurrentRow'
            v-if="TownID"
        >
            <el-table-column type='index' label="序号" width="80"></el-table-column>
            <!-- <el-table-column prop='Province' label='省'></el-table-column> -->
            <el-table-column prop='City' :label='TownID ? "所属镇" : "地区"'>
                <template scope="scope">
                    {{TownID ? scope.row.Town : scope.row.City}}
                </template>
            </el-table-column>
            <el-table-column prop='UnitName' label='单位名称'></el-table-column>
            <!-- <el-table-column prop='Region' label='区/县'></el-table-column> -->
            <el-table-column prop='CertificateRegisterCount' label='持证数量' sortable>
                <template scope="scope">
                    {{scope.row.CertificateRegisterCount ? scope.row.CertificateRegisterCount : 0}}
                </template>
            </el-table-column>
            <!-- <el-table-column prop='CerCount2' label='2本及以上证书单位数' sortable min-width="190">
                <template scope="scope">
                    {{scope.row.CerCount2 ? scope.row.CerCount2 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerCount1' label='1本证书单位数' sortable min-width="150">
                <template scope="scope">
                    {{scope.row.CerCount1 ? scope.row.CerCount1 : 0}}
                </template>
            </el-table-column>
            <el-table-column prop='CerCount0' label='无证书单位数' sortable min-width="150">
                <template scope="scope">
                    {{scope.row.CerCount0 ? scope.row.CerCount0 : 0}}
                </template>
            </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="[10, 40, 60, 120]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount"
            v-if="TownID"
        >
        </el-pagination>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import util from "../../../util/date";
import Qs from 'qs';
import { getAreaDictionaryList,getSocialUnitAggregateListPage,getSocialUnitAggregateTotal,getUserByToken } from '../../api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            provinceOptions:[],
            cityOptions:[],
            areaOptions:[],
            certificatOptions:[
                {value:0,name:'0'},
                {value:1,name:'1'},
                {value:2,name:'2人及以上'},
            ],
            filters:{
                provinceID: undefined,
                cityID:null,
                regionID:null,
                selecAreaArr: [],
                certificateRegisterCount:'',
            },
            tableData:[],
            currentRow:null,
            pages:{
                dataCount: 0,
                pageIndex: 1,
                pageSize: 60,
            },
            listLoading:false,
            provinceLoading:false,
            cityLoading:false,
            areaLoading:false,
            provinceIsDisabled:false,
            cityIsDisabled:false,
            areaIsDisabled:false,
            userInfoByToken:{},
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
            // 顶部查询条件
            proActive: "1919",
            cityActive: "",
            regionActive: "",
            townActive: "",
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
            cityArr: [],
            RegionArr: [],
            TownArr: [],
        }
    },
    methods:{
        // 列表文字居中
        rowClass(){
            return 'padding:8px 0!important;text-align:center;'
        },
        headerClass(){
            return 'text-align:center;'
        },
        // 选择当前行数据
        selCurrentRow(row){
            this.currentRow = row
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value          
            this.getData()
        },
        handleCurrentChange(value){
            // if(this.filters.provinceID != null || this.filters.cityID != null || this.filters.regionID != null || this.filters.certificateRegisterCount != ''){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = value
            // }              
            this.getData()
        },
        // 查询
        getSocialUnitAggregateListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 汇总总计
        getSum(){
            var params = {}
            params.provinceID = this.filters.provinceID ? this.filters.provinceID : ''
            params.cityID = this.filters.cityID ? this.filters.cityID :''
            params.regionID = this.filters.regionID ? this.filters.regionID : ''
            params.certificateRegisterCount = this.filters.certificateRegisterCount ? this.filters.certificateRegisterCount : ''
            getSocialUnitAggregateTotal(params).then(res => {
                if(res.data.Success){
                    this.$alert(`社会单位数量：${res.data.Response.SocialUnitTotalCount == null ? 0 : res.data.Response.SocialUnitTotalCount}   证书数量：${res.data.Response.CertificateRegisterTotalCount == null? 0 : res.data.Response.CertificateRegisterTotalCount}`, '汇总总计', {
                    confirmButtonText: '确定',
                    callback: () => {}
                    });
                }
            }) 
        },
        // 获取列表数据
        getData(){
            this.listLoading = true
            var params = {
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize,
                provinceID: 1919,
                cityID: this.CityID,
                regionID: this.RegionID,
                townID: this.TownID,
            }
            // params.provinceID = this.filters.provinceID ? this.filters.provinceID : ''
            // params.cityID = this.filters.cityID ? this.filters.cityID : ''
            // params.regionID = this.filters.regionID ? this.filters.regionID : ''
            // params.certificateRegisterCount = this.filters.certificateRegisterCount ? this.filters.certificateRegisterCount : ''
            // if(this.filters.selecAreaArr.length > 0) {
            //     params.cityID = this.filters.selecAreaArr[0]
            // }
            // if(this.filters.selecAreaArr.length > 1) {
            //     params.regionID = this.filters.selecAreaArr[1]
            // }
            getSocialUnitAggregateListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    var newObj = {
                        City: "合计",
                        Town: "合计",
                        UnitName: "-",
                        SocialUnitCount: 0,
                        CerCount2: 0,
                        CerCount1: 0,
                        CerCount0: 0,
                        CerProportion: "-",
                    }
                    if(res.data.Response.Data && res.data.Response.Data.length > 0) {
                        res.data.Response.Data.forEach(item => {
                            newObj.SocialUnitCount += item.SocialUnitCount
                            newObj.CerCount2 += item.CerCount2
                            newObj.CerCount1 += item.CerCount1
                            newObj.CerCount0 += item.CerCount0
                        })
                    }
                    res.data.Response.Data.unshift(newObj)
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {})
        },
        // 获取行政区信息
        getPlace(params){
            switch(params.level){
                case 0:
                    this.provinceLoading = true
                    break;
                case 1:
                    this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break;
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(params.level){
                        case 0:
                            this.provinceLoading = false
                            this.provinceOptions = res.data.Response.reverse();
                            break;
                        case 1:
                            this.cityLoading = false
                            this.cityOptions = res.data.Response.reverse();
                            break;
                        case 2:
                            this.areaLoading = false
                            this.areaOptions = res.data.Response.reverse();
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 得到用户的省市区信息
        getUserInfo(){
            // var token = localStorage.getItem("Token")
            // getUserByToken({token:token}).then(res => {
            //     if(res.Success){
                    // var data = res.Response
                    var data = JSON.parse(localStorage.getItem("user"))
                    this.userInfoByToken = data    
                    if(data.ProvinceID == null){
                        this.provinceOptions = []
                        this.filters.provinceID = ''
                    }else{
                        var params = {
                            parentId:data.ProvinceID,
                            level:1
                        }
                        this.getPlace(params)
                        this.provinceIsDisabled = true
                    }  
                    if(data.CityID == null){
                        this.cityOptions = []
                        this.filters.cityID = ''
                    }else{
                        var params = {
                            parentId:data.CityID,
                            level:2
                        }
                        this.getPlace(params)
                        this.cityIsDisabled = true
                    }
                    if(data.RegionID == null){
                        this.areaOptions = []
                        this.filters.regionID = ''
                    }else{
                        this.areaIsDisabled = true
                    }                     
                    this.filters.provinceID = data.ProvinceID
                    this.filters.cityID = data.CityID
                    this.filters.regionID = data.RegionID
                    this.getData()
            //     }else{return}
            // }).catch(() =>{})
        },
        // 选中省
        provinceChange(province){
            if(!province){
                this.cityOptions = []
                this.areaOptions = []
                this.filters.cityID = null
                this.filters.regionID = null
            }else{
                var params = {
                    level:1,
                    parentId:province
                }
                this.getPlace(params)
            }         
        },
        // 选中市
        cityChange(city){
            if(!city){
                this.areaOptions = []
                this.filters.regionID = null
            }else{
                var params = {
                    level:2,
                    parentId:city
                }
                this.getPlace(params)
            }          
        },       
        // 筛选框清空
        handleClear(){
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 顶部查询条件
        // 省市区切换
        proSelect(val) {
            this.getCityData()
            this.RegionArr = []
            this.TownArr = []
            this.CityID = ""
            this.RegionID = ""
            this.TownID = ""
            this.cityActive = ""
            this.regionActive = ""
            this.townActive = ""
            var that = this
            setTimeout(() => {
                that.getData()
            });
        },
        citySelect(val) {
            this.getRegionData(val)
            this.TownArr = []
            this.CityID = val
            this.RegionID = ""
            this.TownID = ""
            this.regionActive = ""
            this.townActive = ""
            var that = this
            setTimeout(() => {
                that.getData()
            });
        },
        regionSelect(val) {
            this.getTownData(val)
            this.RegionID = val
            this.TownID = ""
            this.townActive = ""
            var that = this
            setTimeout(() => {
                that.getData()
            });
        },
        townSelect(val) {
            this.TownID = val
            var that = this
            setTimeout(() => {
                that.getData()
            });
        },
        // 获取广东各个市
        getCityData() {
            var params = {
                parentId: 1919
            }
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.cityArr = []
                    setTimeout(() => {
                        this.cityArr = result.Response
                    });
                }
            })
        },
        // 获取广东各个区/县
        getRegionData(val) {
            var params = {
                parentId: val
            }
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.RegionArr = []
                    setTimeout(() => {
                        this.RegionArr = result.Response
                    })
                }
            })
        },
        // 获取广东各个乡镇
        getTownData(val) {
            var params = {
                parentId: val
            }
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.TownArr = []
                    setTimeout(() => {
                        this.TownArr = result.Response
                    })
                }
            })
        },
    },
    mounted(){
        // this.getData()
        this.getUserInfo()
        this.getCityData();
        // var params = {level:0}
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getPlace(params)
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>